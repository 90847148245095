import {
    cercaLogo,
    getCaducitat,
    getDurada,
    hasEtiquetaDocumental,
    isPeli,
    isSerie,
    removeHtmlTags,
    trimBR
} from '@portal-internet/core';
import clsx from 'clsx';
import CodiEspectador from 'components/3cat/CodiEspectador';
import Enllac from 'components/3cat/Enllac';
import Imatge from 'components/3cat/Imatge';
import Boto from 'components/ui/Boto';
import Icona from 'components/3cat/ui/Icona';
import LogoDirecte from 'components/3cat/ui/LogoDirecte/LogoDirecte';
import ProgressBar from 'components/3cat/ui/ProgressBar';
import { useEstasVeient } from 'hooks/3cat/useEstasVeient';
import { useProducte } from 'hooks/3cat/useProducte';
import { typesViewport, useViewport } from 'hooks/useViewport';
import __isEmpty from 'lodash/isEmpty';
import __isUndefined from 'lodash/isUndefined';
import { useEffect } from 'react';
import Share from './Share';
import styles from './destacat.module.scss';

const getTextBoto = (
    textBoto,
    iconaBoto,
    tipologia,
    isSuperdestacatTematic,
    isVideoResumed,
    callToAction,
    isDirecteDestacat,
    hasCanalVideo,
    hasCanalRadio
) => {
    let copyBoto = tipologia === 'DTY_DESTACAT' ? callToAction : textBoto;
    const isAudio = tipologia === 'WCR_AUDIO_MM';
    const isPrograma = tipologia === 'PTVC_PROGRAMA' || tipologia === 'WCR_PROGRAMA';

    let text = isAudio ? 'ESCOLTA-HO ARA' : 'MIRA-HO ARA';
    text = isSuperdestacatTematic && isPrograma ? 'VES-HI ARA' : text;
    text = !__isEmpty(copyBoto) ? copyBoto : text;
    text = isVideoResumed ? 'CONTINUA' : text;
    text = isDirecteDestacat && hasCanalVideo ? 'Mira-ho en directe' : text;
    text = isDirecteDestacat && hasCanalRadio ? 'Escolta-ho en directe' : text;

    let icona = isAudio ? 'auriculars_ple' : 'play';
    icona = (isSuperdestacatTematic && isPrograma) || (tipologia === 'DTY_DESTACAT' && !__isEmpty(callToAction)) ? '' : icona;
    icona = isDirecteDestacat ? 'directes_ple' : icona;
    icona = iconaBoto === false ? '' : icona;
    icona = !__isEmpty(iconaBoto) ? iconaBoto : icona;

    return { text, icona };
};

const isDisponible = (route) => {
    if (
        (__isEmpty(route.href) || __isUndefined(route.href)) &&
        (__isEmpty(route.id) || __isUndefined(route.id)) &&
        (__isEmpty(route.slug) || __isUndefined(route.slug)) &&
        (__isEmpty(route.slugPrograma) || __isUndefined(route.slugPrograma)) &&
        (__isEmpty(route.tipologia) || __isUndefined(route.tipologia))
    ) {
        return false;
    }

    return true;
};

const getSuperTitol = (titolLogo, supertitol, viewport) => {
    if (viewport !== typesViewport.VP_MOBILE && titolLogo) {
        return (
            <div className={styles.logoSupertitol}>
                <LogoDirecte className={styles[titolLogo]} canal={titolLogo} icona={titolLogo} />
            </div>
        );
    } else {
        return !__isEmpty(supertitol) && <h1 className={styles.supertitol}>{supertitol}</h1>;
    }
};

const getContentDurada = (durada, isVideoResumed, resumeText) => {
    return (
        <>
            <Icona
                className={styles.ico_clock}
                icona='rellotge'
                isBlanc={true}
                mida='16'
                alt='Icona rellotge'
                ariaHidden={true}
            />
            <span>
                {isVideoResumed && resumeText ? (
                    resumeText
                ) : (
                    <>
                        <span className='sr-only'>Durada: </span>
                        {getDurada(String(durada))}
                    </>
                )}
            </span>
        </>
    );
};
const Destacat = (props) => {
    const {
        supertitol,
        titolLogo,
        avantitol,
        titolPrograma,
        className,
        tipus,
        format,
        formats,
        codiEspectador,
        infoDistribucio,
        link,
        linkTipus,
        tipologia,
        tipologiaRelacionada,
        slugPrograma,
        programaId,
        programaTipologia,
        canals,
        tema,
        field,
        callToAction,
        agrupacions,
        dataCaducitat,
        dataTestId
    } = props;
    const { viewport } = useViewport();
    let { titol, durada, imatges, imatgesPrograma, nombonic, titolPromo, linkId, permatitle, etiqueta } = props;
    let entradeta = trimBR(props.entradeta);
    let entradetaPromo = trimBR(props.entradetaPromo);
    const isDestacat = tipologia === 'DTY_DESTACAT';
    const hasAgrupadoraDirecte = agrupacions?.some((agrupadora) => agrupadora.id === 'DIRECTE3CAT');
    const hasCanalRadio = ['PUCCR', 'PUCCM', 'PUCCI', 'PUCICAT', 'PUCCREI'].includes(canals?.[0].id.replace('_', ''));
    const hasCanalVideo = !__isEmpty(canals?.[0].id) && !hasCanalRadio;
    const isDirecteVideo = isDestacat && hasCanalVideo && hasAgrupadoraDirecte;
    const isDirecteRadio = isDestacat && hasCanalRadio && hasAgrupadoraDirecte;
    const isDirecteDestacat = isDirecteVideo || isDirecteRadio;
    const canalDirecte = isDirecteDestacat && canals ? canals[0]?.id.toLowerCase().replace('_', '').replace('puc', '') : null;

    const optionsEstasVeient = {
        loadData: true,
        programaId,
        durada,
        id: linkId
    };

    const { percentatgeVist, isVideoResumed, isVideoCompleted, resumeText, itemEstasVeient, refetchEstasVeientOptions } =
        useEstasVeient(optionsEstasVeient);

    const { isWebviewActive } = useProducte();
    const isSerial = isSerie(formats);
    const isDocumentals = supertitol === 'Documentals i reportatges';
    let _tipus = tipus.toUpperCase();

    const mostrarCaducitat = dataCaducitat && getCaducitat(dataCaducitat);
    const isSuperdestacatTematic = _tipus === 'SUPERDESTACATTEMATIC' || _tipus === 'SUPERDESTACAT';
    const isProgramaEmissio = _tipus === 'PROGRAMA';
    const isEstrena = _tipus === 'ESTRENA';
    const isArxiu = _tipus === 'ARXIU';
    const isPellicula = isPeli(format || formats);
    const isDocumental = hasEtiquetaDocumental(formats, isPellicula, titolPrograma, false, tipologia);

    const isDirecte = tipologia === 'DIRECTES_ARAFEM';
    let { textBoto, iconaBoto } = props;
    let mostraBotoReproduir =
        (!isEstrena && !(tipologia === 'DTY_DESTACAT' && __isEmpty(callToAction)) && !__isEmpty(tipologia)) || isDirecteDestacat;
    let mostraTitolEnEnllac = isArxiu || isProgramaEmissio;
    let mostraTitolSenseEnllac = isArxiu || isEstrena;
    let mostraEntradeta = isProgramaEmissio || isSuperdestacatTematic;

    const botoDestacat = getTextBoto(
        textBoto,
        iconaBoto,
        tipologia,
        isSuperdestacatTematic,
        isVideoResumed,
        callToAction ? callToAction.toUpperCase() : null,
        hasAgrupadoraDirecte,
        hasCanalVideo,
        hasCanalRadio
    );
    textBoto = botoDestacat.text;
    iconaBoto = botoDestacat.icona;

    const stylesDestacat = clsx({
        [styles.destacat]: true,
        [styles.bigDestacat]: !__isEmpty(supertitol),
        [styles.documentals]: !__isEmpty(supertitol) && isDocumentals,
        [styles.peliDestacat]: isPellicula,
        [styles[className]]: !__isEmpty(className),
        [styles.destacatEsticVeient]: isVideoResumed,
        [styles.estrena]: isEstrena
    });

    const route = {
        href: link,
        slug: nombonic || permatitle || titol,
        slugPrograma: slugPrograma,
        id: linkId,
        type: linkTipus,
        tipologia: tipologia,
        tipologiaRelacionada: tipologiaRelacionada,
        canals: canals,
        format: isWebviewActive ? 'PLAYER' : null,
        agrupadores: agrupacions,
        tipusDirecte: isDirecteVideo ? 'tv' : isDirecteRadio ? 'radio' : null
    };
    const hasLogo = cercaLogo(imatges, 'img_logo');
    const hasLogoPrograma = cercaLogo(imatgesPrograma, 'img_logo');
    const imatgeslogo = hasLogo ? imatges : imatgesPrograma;

    const stylesDescripcio = clsx({
        [styles.descripcio]: true,
        [styles.ambLogo]: hasLogo || hasLogoPrograma
    });

    useEffect(() => {
        if (itemEstasVeient && isSerial) {
            refetchEstasVeientOptions({ durada, id: linkId });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemEstasVeient]);

    useEffect(() => {
        if (isSerial) {
            refetchEstasVeientOptions({ loadData: true, programaId: programaId });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [programaId]);

    if (itemEstasVeient && isSerial) {
        durada = itemEstasVeient.durada;
        entradeta = itemEstasVeient.entradeta;
        entradetaPromo = trimBR(itemEstasVeient.entradeta_promo);
        titol = trimBR(itemEstasVeient.titol);
        nombonic = itemEstasVeient.nombonic;
        imatges = itemEstasVeient.imatges;
        linkId = itemEstasVeient.id;
        titolPromo = itemEstasVeient.titol_promo;
        route.slug = nombonic ? nombonic : titol;
        route.id = linkId;
        if (isEstrena) {
            mostraBotoReproduir = true;
            mostraTitolEnEnllac = true;
        }
        mostraTitolSenseEnllac = false;
        mostraEntradeta = true;
    }

    const _getTitolDestacat = (tipus) => {
        return ['PROGRAMA', 'ESTRENA', 'ARXIU'].includes(tipus) && !isSuperdestacatTematic ? titol : titolPromo || titol;
    };

    const _getEntradeta = () => {
        return entradetaPromo || entradeta;
    };

    const _getProgressBar = (device) => {
        if (device === 'desktop' && !isWebviewActive && viewport !== typesViewport.VP_MOBILE) {
            if (isVideoCompleted || (itemEstasVeient && isVideoResumed)) {
                return <ProgressBar className={styles.progress} progress={percentatgeVist} />;
            }
        } else if (device === 'mobile' && (isWebviewActive || viewport === typesViewport.VP_MOBILE)) {
            if (isVideoCompleted || (itemEstasVeient && isVideoResumed)) {
                return (
                    <div className={styles.dadesVideoContinuar}>
                        <div>
                            <ProgressBar className={styles.progress} progress={percentatgeVist} />
                        </div>
                    </div>
                );
            }
        }

        return <></>;
    };

    const _getEtiqueta = () => {
        const isVideo = tipologia === 'DTY_VIDEO_MM';

        if (isPellicula) {
            return (
                <>
                    <span className={styles.movieLabel} data-testid='destacat-tag-peli' aria-label='pel·lícula'>
                        Pel·lícula
                    </span>
                    <h2>
                        <span>{_getTitolDestacat()}</span>
                    </h2>
                </>
            );
        } else if (isDocumental) {
            return (
                <span className={styles.movieLabel} data-testid='destacat-tag-peli' aria-label='Documental'>
                    Documental
                </span>
            );
        } else if (etiqueta && etiqueta.length < 15) {
            return (
                <span className={styles.movieLabel} aria-label={etiqueta}>
                    {etiqueta}
                </span>
            );
        } else if (isVideo && avantitol && (__isUndefined(titolPrograma) || __isEmpty(titolPrograma))) {
            return (
                <span className={styles.movieLabel} aria-label={avantitol}>
                    {avantitol}
                </span>
            );
        }

        return <></>;
    };

    const stylesTitolPrograma = isSuperdestacatTematic || mostraTitolEnEnllac ? 'titolSenseLogoBold' : 'titolSenseLogo';

    return (
        <div className={stylesDestacat} data-testid={dataTestId}>
            {getSuperTitol(titolLogo, supertitol, viewport)}

            <section className={styles.cos}>
                <div className={stylesDescripcio}>
                    {_getEtiqueta()}
                    {isDirecteDestacat && <LogoDirecte canal={canalDirecte} className={styles.logodirecte} />}
                    {(hasLogo || hasLogoPrograma) && (
                        <div className={styles.logo}>
                            <Enllac disponible={isDisponible(route)} route={route} dataTestId='destacat-enllac-logo'>
                                <Imatge
                                    data_testid='destacat-logo'
                                    imatges={imatgeslogo}
                                    tipus='web_logo'
                                    layout='fill'
                                    objectFit='contain'
                                    crop='310'
                                    alt={titolPrograma || titol}
                                    mostrarRecurs={false}
                                />
                            </Enllac>
                        </div>
                    )}
                    {!hasLogo && !hasLogoPrograma && !isPellicula && (
                        <div className={styles[stylesTitolPrograma]}>{titolPrograma}</div>
                    )}
                    {(isProgramaEmissio || isEstrena || isArxiu) && (
                        <div className={styles.logoSharing + ' ' + styles.sharingTop}>
                            <Share
                                {...props}
                                route={route}
                                isWebviewActive={isWebviewActive}
                                tipologia={programaTipologia}
                                linkId={programaId}
                            />
                        </div>
                    )}
                    {infoDistribucio && (
                        <div data-testid='destacat-infodistribucio' className={styles.infoDistribucio}>
                            {infoDistribucio}
                        </div>
                    )}
                    {isSuperdestacatTematic && !isPellicula && !hasLogo && (
                        <Enllac route={route} dataTestId='destacat-enllac-titol'>
                            {!__isEmpty(avantitol) && tipologia === 'DTY_DESTACAT' && (
                                <span className={styles.avantitol}>{avantitol}</span>
                            )}
                            <h2>
                                <span data-testid='destacat-titol'>{_getTitolDestacat(_tipus)}</span>
                            </h2>
                        </Enllac>
                    )}

                    {mostraTitolSenseEnllac && !__isEmpty(_getEntradeta()) && <p>{removeHtmlTags(_getEntradeta())}</p>}
                    {mostraTitolEnEnllac && _getTitolDestacat(_tipus) && (
                        <Enllac route={route} dataTestId='destacat-enllac-titol'>
                            {!__isEmpty(avantitol) && <span className={styles.avantitol}>{avantitol}</span>}
                            <h2>
                                <span data-testid='destacat-titol'>{_getTitolDestacat(_tipus)}</span>
                            </h2>
                        </Enllac>
                    )}
                    {((!__isEmpty(codiEspectador) && codiEspectador[0].id != 'CE_TP') || !__isEmpty(durada)) && (
                        <div className={styles.dadesVideo}>
                            {!__isEmpty(codiEspectador) && (
                                <CodiEspectador
                                    className={styles.ico_codi}
                                    codi={codiEspectador[0].id}
                                    alt={codiEspectador[0].desc}
                                    mida='20'
                                />
                            )}

                            {!__isEmpty(durada) && (
                                <div className={styles.durada} data-testid='destacat-durada'>
                                    {getContentDurada(durada, isVideoResumed, resumeText)}

                                    {mostrarCaducitat && !isWebviewActive && viewport !== typesViewport.VP_MOBILE && (
                                        <div data-testid='destacat-data-caducitat' className={styles.pubdate}>
                                            {getCaducitat(dataCaducitat)}
                                        </div>
                                    )}
                                    {_getProgressBar('desktop')}
                                </div>
                            )}
                        </div>
                    )}
                    {_getProgressBar('mobile')}

                    {mostraEntradeta && !__isEmpty(_getEntradeta()) && (
                        <p className={styles.textEntradeta}>{removeHtmlTags(_getEntradeta())}</p>
                    )}

                    {mostraBotoReproduir && (
                        <Boto
                            dataTestId={'boto-destacat'}
                            text={textBoto}
                            icona={iconaBoto}
                            altIcona='Icona reproduïr'
                            className={styles.boto}
                            iconaInline={true}
                            isIconaDreta={true}
                            tag='a'
                            canals={canals}
                            format={isWebviewActive ? 'PLAYER' : ''}
                            type={linkTipus}
                            link={link}
                            estil='ple'
                            tema={tema}
                            fons='fosc'
                            route={route}
                        />
                    )}
                    {isSuperdestacatTematic && !isDirecte && (
                        <div className={styles.logoSharing}>
                            <Share {...props} />
                        </div>
                    )}
                </div>

                <div className={styles.imatgeWrapper}>
                    <Imatge
                        imatges={imatges}
                        className={styles.imatge}
                        tipus='web_destacat'
                        alt={titol}
                        aria-hidden='true'
                        layout='fill'
                        loading='lazy'
                        objectFit='cover'
                        priority={false}
                        crop='1903'
                    />
                </div>
            </section>
        </div>
    );
};

Destacat.defaultProps = {
    titol: '',
    entradeta: '',
    tipus: '',
    field: 'POSTER_PETIT',
    isWebviewActive: false
};

export default Destacat;
